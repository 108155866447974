/** @jsx jsx */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Grid, jsx } from "theme-ui"

import ImageWithEnhancements from "./ImageWithEnhancements"

export const fragment = graphql`
  fragment Images on ContentfulImages {
    images {
      ...ImageWithEnhancements
    }
  }
`

export default function Images({ media }) {
  if (Array.isArray(media?.images) && media.images.length) {
    return (
      <Grid
        as="div"
        sx={{
          gridTemplateAreas: `
            ".    img1 img1 img1 .    .    .    ."
            ".    img1 img1 img1 .    .    .    ."
            ".    img1 img1 img1 img2 img2 img2 img2"
            ".    img1 img1 img1 img2 img2 img2 img2"
            "img3 img3 img3 img3 img2 img2 img2 img2"
            "img3 img3 img3 img3 img2 img2 img2 img2"
            "img3 img3 img3 img3 img2 img2 img2 img2"
            "img3 img3 img3 img3 img2 img2 img2 img2"
            "img3 img3 img3 img3 .    .    .    ."
          `,
          "div:nth-of-type(1)": {
            gridArea: "img1",
            "@media screen and (max-width: 600px)": {
              "::after": {
                height: 50,
                left: "-35px",
                top: "-45px",
                width: 50,
              },
            },
          },
          "div:nth-of-type(2)": {
            gridArea: "img2",
            "@media screen and (max-width: 600px)": {
              "::after": {
                bottom: "-40px",
                height: 50,
                right: "-35px",
                width: 50,
              },
            },
          },
          "div:nth-of-type(3)": {
            gridArea: "img3",
          },
          margin: ["0 auto", "unset"],
          maxWidth: ["80%", "unset"],
        }}
      >
        {media.images.map(image => (
          <ImageWithEnhancements key={image.id} media={image} nomqs />
        ))}
      </Grid>
    )
  }

  return null
}

Images.propTypes = {
  media: PropTypes.object.isRequired,
}
