/** @jsx jsx */
import BroccForm from "@components/blocks/Form/BroccForm"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

import Animation from "./Animation"
import Images from "./Images"
import Image from "@components/Image"
import ImageWithEnhancements from "./ImageWithEnhancements"
import GatsbyImage from "gatsby-image"

export const fragment = graphql`
  fragment Media on MediaUnion {
    __typename
    ... on ContentfulAnimation {
      ...Animation
    }
    ... on ContentfulForm {
      ...MediaForm
    }
    ... on ContentfulImage {
      ...ImageWithEnhancements
    }
    ... on ContentfulImages {
      ...Images
    }
  }
`

export default function Media({ media, roundedImage }) {
  if (media && media.__typename) {
    switch (media.__typename) {
      case "ContentfulAnimation":
        return <Animation json={media.json} />
      case "ContentfulForm":
        return (
          <BroccForm
            bgc={media.backgroundcolor}
            hero
            loan
            widget={media.formWidget}
          />
        )
      case "ContentfulImage": {
        return roundedImage ? (
          <GatsbyImage
            fluid={media.image.fluid}
            sx={{
              height: "100%",
              width: "100%",
              borderRadius: "20px",
              "@media(min-width: 768px)": {
                height: "70%",
              },
            }}
          />
        ) : (
          <ImageWithEnhancements media={media} />
        )
      }
      case "ContentfulImages": {
        return <Images media={media} />
      }
    }
  }

  return null
}

Media.propTypes = {
  media: PropTypes.object.isRequired,
}
