export function get(obj, arg, fallback = null) {
  if (obj == null || Array.isArray(obj) || typeof obj !== "object") return
  let args = []
  if (typeof arg === "string") {
    args = arg.split(".")
  }
  if (Array.isArray(arg)) {
    args = arg
  }

  return args.reduce((acc, val, idx, arr) => {
    if (acc == null || typeof acc !== "object") {
      return fallback
    }

    if (Object.prototype.hasOwnProperty.call(acc, val)) {
      return acc[val]
    }

    if (idx !== arr.length - 1) {
      return Object.create(Object.prototype)
    }

    return fallback
  }, obj)
}

function createSlugParameters(source, pathParams = []) {
  const parent = get(source, "fields.parent")
  if (parent) {
    pathParams.unshift(get(parent, "fields.slug"))
    createSlugParameters(parent, pathParams)
  }

  return pathParams
}

function handleLinks(fields, obj) {
  if (Object.prototype.hasOwnProperty.call(fields, "internalLink")) {
    const internalLink = get(fields, "internalLink")
    const slugs = createSlugParameters(internalLink)
    slugs.push(internalLink.fields.slug)
    return Object.assign(
      {},
      {
        slug: `/${slugs.join("/")}`,
      },
      obj
    )
  }

  if (Object.prototype.hasOwnProperty.call(fields, "externalLink")) {
    const externalLink = get(fields, "externalLink")
    return Object.assign(
      {},
      {
        href: externalLink,
      },
      obj
    )
  }

  return null
}

export function createButton(node) {
  const fields = get(node, "data.target.fields", {})
  const align = get(fields, "align")
  const title = get(fields, "title")

  return handleLinks(fields, { align, title })
}

export function createAnchorTag(node) {
  const fields = get(node, "data.target.fields", {})
  const title = get(fields, "title")

  return handleLinks(fields, { title })
}
