/** @jsx jsx */
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { createAnchorTag, createButton, get } from "@src/lib/inline-entry"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { Fragment } from "react"
import { jsx } from "theme-ui"
import pdf from "../../static/imgs/pdf.png"

const options = (paragraphVariant, center, textStyle) => {
  const textAlign = textStyle === "Black text" ? "left" : null
  return {
    renderMark: {
      [MARKS.BOLD]: function boldRenderer(text) {
        return (
          <b key={`${text}-key`} sx={{ variant: "richtext.b" }}>
            {text}
          </b>
        )
      },
      [MARKS.CODE]: function codeRenderer(text) {
        return (
          <code key={`${text}-key`} sx={{ variant: "richtext.code" }}>
            {text}
          </code>
        )
      },
      [MARKS.ITALIC]: function italicRenderer(text) {
        return (
          <i key={`${text}-key`} sx={{ variant: "richtext.i" }}>
            {text}
          </i>
        )
      },
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        if (textStyle === "Black text") {
          const sxStyle = {
            display: "inline-block",
            color: "black",
            fontWeight: "bold",
            fontSize: "16px",
            textDecoration: "none, underline",
          }
          const linkItem = node.data.uri.includes("http") ? (
            <a
              rel="noreferrer noopener"
              sx={sxStyle}
              target="_blank"
              href={node.data.uri}
            >
              {children}
            </a>
          ) : (
            <Link sx={sxStyle} to={node.data.uri}>
              {children}
            </Link>
          )
          return <div sx={{ marginTop: "-20px" }}>{linkItem}</div>
        }
        if (node.data.uri.includes("@")) {
          return <a href={`mailto: ${node.data.uri}`}>{children}</a>
        }
        const linkItem = node.data.uri.includes("http") ? (
          <a rel="noreferrer noopener" target="_blank" href={node.data.uri}>
            {children}
          </a>
        ) : (
          <Link to={node.data.uri}>{children}</Link>
        )
        return linkItem
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { file } = node.data?.target?.fields

        return (
          <img
            sx={{
              height: "300px",
              width: "100%",
              objectFit: "cover",
              borderRadius: "20px",
              mb: 10,
              mt: 10,
            }}
            src={file.url}
          />
        )
      },

      [BLOCKS.PARAGRAPH]: function paragraphRenderer(node, children) {
        if (textStyle !== "Black text") {
          let variant = "richtext.p"
          if (paragraphVariant === "preamble") {
            variant = "text.preamble"
          } else if (paragraphVariant === "large") {
            variant = "text.large"
          } else if (paragraphVariant === "small") {
            variant = "text.small"
          }
          return (
            <p
              sx={{
                variant,
                maxWidth: 850,
                margin: center ? "0 auto" : 0,
                marginBottom: "20px",
              }}
            >
              {children}
            </p>
          )
        } else
          return (
            <p
              sx={{
                maxWidth: 850,
                color: "black",
                fontSize: "20px",
                lineHeight: "1.4",
                marginTop: "-35px",
              }}
            >
              {children}
            </p>
          )
      },
      [BLOCKS.HEADING_1]: function heading1Renderer(node, children) {
        return <h1 sx={{ variant: "richtext.h1" }}>{children}</h1>
      },
      [BLOCKS.HEADING_2]: function heading2Renderer(node, children) {
        return (
          <h2
            sx={{
              variant: "richtext.h2",
              textAlign: textAlign,
            }}
          >
            {children}
          </h2>
        )
      },
      [BLOCKS.HEADING_3]: function heading3Renderer(node, children) {
        return (
          <h3
            sx={{
              variant: "richtext.h3",
              textAlign: center,
            }}
          >
            {children}
          </h3>
        )
      },
      [BLOCKS.HEADING_4]: function heading4Renderer(node, children) {
        return <h4 sx={{ variant: "richtext.h4" }}>{children}</h4>
      },
      [BLOCKS.HEADING_5]: function heading5Renderer(node, children) {
        return <h5 sx={{ variant: "richtext.h5" }}>{children}</h5>
      },
      [BLOCKS.HEADING_6]: function heading6Renderer(node, children) {
        return <h6 sx={{ variant: "richtext.h6" }}>{children}</h6>
      },
      [BLOCKS.UL_LIST]: function ulRenderer(node, children) {
        return <ul sx={{ variant: "richtext.ul" }}>{children}</ul>
      },
      [BLOCKS.OL_LIST]: function olRenderer(node, children) {
        return <ol sx={{ variant: "richtext.ol" }}>{children}</ol>
      },
      [BLOCKS.LIST_ITEM]: function listItemRenderer(node, children) {
        return <li sx={{ variant: "richtext.li" }}>{children}</li>
      },
      [BLOCKS.QUOTE]: function quoteRenderer(node, children) {
        return (
          <blockquote sx={{ variant: "richtext.blockquote" }}>
            {children}
          </blockquote>
        )
      },
      [BLOCKS.HR]: function hrRenderer() {
        return <hr sx={{ variant: "richtext.hr" }} />
      },
      // eslint-disable-next-line no-unused-vars
      "embedded-entry-inline": function inlineEntryRenderer(node, children) {
        const type = get(node, "data.target.sys.contentType.sys.contentful_id")

        if (type === "button") {
          const button = createButton(node)

          const styles = {
            variant: "button.primary",
            color: "white !important",
            fontSize: "inherit",
            left:
              button.align === "Center"
                ? "50%"
                : button.align === "Right"
                ? "100%"
                : "0px",
            mt: 12,
            position: "relative",
            textDecoration: "none !important",
            transform:
              button.align === "Center"
                ? "translateX(-50%)"
                : button.align === "Right"
                ? "translateX(-100%)"
                : "unset",
            width: "max-content",
          }

          if (button.slug) {
            return (
              <Link sx={styles} to={button.slug}>
                {button.title}
              </Link>
            )
          }
          return (
            <a target="_blank" href={button.href} sx={styles}>
              {node?.data?.target?.fields?.externalLink.includes(".pdf") && (
                <img sx={{ marginRight: "14px" }} alt="pdf" src={pdf} />
              )}
              {button.title}
            </a>
          )
        }

        if (type === "linkItem") {
          const link = createAnchorTag(node)

          const styles = {
            color: "primary",
            fontSize: "inherit",
            fontWeight: "bold",
            textDecoration: "underline",
          }

          if (link.slug) {
            return (
              <Link sx={styles} to={link.slug}>
                {link.title}
              </Link>
            )
          }

          if (link.href) {
            return (
              <a href={link.href} sx={styles}>
                {link.title}
              </a>
            )
          }
        }

        return null
      },
    },
  }
}

const optionsBlackText = () => {
  return {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        return (
          <a
            sx={{
              color: "black",
              fontWeight: "bold",
              fontSize: "16px",
            }}
            href={node.data}
          >
            {children}
          </a>
        )
      },
      [BLOCKS.HEADING_3]: function heading3Renderer(node, children) {
        return (
          <h3 sx={{ variant: "richtext.h3", textAlign: "left" }}>{children}</h3>
        )
      },
      [BLOCKS.HEADING_2]: function heading3Renderer(node, children) {
        return <h2 sx={{ variant: "richtext.h3", textAlign: "left" }}>boop</h2>
      },
      [BLOCKS.HEADING_1]: function heading3Renderer(node, children) {
        return (
          <h1 sx={{ variant: "richtext.h3", textAlign: "left" }}>{children}</h1>
        )
      },
      [BLOCKS.HEADING_4]: function heading3Renderer(node, children) {
        return (
          <h4 sx={{ variant: "richtext.h3", textAlign: "left" }}>{children}</h4>
        )
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <p
            sx={{
              maxWidth: 850,
              color: "black",
              fontSize: "20px",
              lineHeight: "1.4",
              marginTop: "-60px",
            }}
          >
            {children}
          </p>
        )
      },
    },
  }
}

export default function RichText({ center, json, pv, textStyle }) {
  return (
    <Fragment>
      {documentToReactComponents(json, options(pv, center, textStyle))}
    </Fragment>
  )
}

RichText.defaultProps = {
  center: false,
  pv: undefined,
}

RichText.propTypes = {
  center: PropTypes.bool,
  json: PropTypes.object.isRequired,
  pv: PropTypes.string,
}
