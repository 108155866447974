/** @jsx jsx */
import { useLocation } from "@reach/router"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from "react"
import { jsx } from "theme-ui"

import useLocaleContext from "@hooks/useLocaleContext"

/** Only used with Media */
export const fragment = graphql`
  fragment MediaForm on ContentfulForm {
    backgroundcolor
    formWidget
  }
`

export default function BroccForm({ bgc, hero, loan, widget, messageHandler }) {
  const iframeRef = useRef(null)
  const { search } = useLocation()
  const locale = useLocaleContext()

  const { brocc } = useStaticQuery(
    graphql`
      {
        brocc {
          origin
        }
      }
    `
  )

  const config = useMemo(
    () => ({
      origin: brocc.origin,
      src: search
        ? `${brocc.origin}/widget/${widget}${search}&lang=${locale}`
        : `${brocc.origin}/widget/${widget}?lang=${locale}`,
    }),
    [brocc.origin, search, widget, locale]
  )

  const handleMessage = useCallback(
    evt => {
      const { data, origin } = evt

      if (origin !== config.origin) {
        return
      }

      if (!data) {
        return
      }

      if (messageHandler) {
        messageHandler(data)
      }

      if (data.height) {
        iframeRef.current.style.height = data.height + "px"
      }
    },
    [config, iframeRef]
  )

  const handleResize = useCallback(() => {
    iframeRef?.current?.contentWindow?.postMessage("height", config.origin)
  }, [config, iframeRef])

  const handleResponsiveness = useCallback(() => {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
      const message = window?.innerWidth < 768 ? "font-sm" : "font-regular"
      iframeRef?.current?.contentWindow?.postMessage(message, config.origin)
    }
    handleResize()
  }, [config.origin, handleResize])

  useEffect(() => {
    const iframe = iframeRef?.current
    if (iframe) {
      window.addEventListener("load", handleResponsiveness)
      window.addEventListener("message", handleMessage)
      window.addEventListener("resize", handleResponsiveness)
    }

    return () => {
      if (iframe) {
        window.removeEventListener("load", handleResponsiveness)
        window.removeEventListener("message", handleMessage)
        window.removeEventListener("resize", handleResponsiveness)
      }
    }
  }, [handleMessage, handleResponsiveness, iframeRef])

  useLayoutEffect(() => {
    iframeRef.current.onload = () => {
      handleResize()
    }
  }, [handleResize, iframeRef])

  useEffect(() => {
    const intervalID = setInterval(() => {
      handleResize()
    }, 50)

    const timeoutID = setTimeout(() => {
      if (intervalID) {
        clearInterval(intervalID)
      }
    }, 5000)

    return () => {
      if (intervalID) {
        clearInterval(intervalID)
      }
      if (timeoutID) {
        clearTimeout(timeoutID)
      }
    }
  }, [handleResize])

  return (
    <div
      sx={{
        backgroundColor: `rgba(${bgc})`,
        borderRadius: [null, "20px"],
        padding: loan ? "30px 0px 46px" : "0px",
        position: "relative",
        width: "100%",

        "@media screen and (min-width: 992px)": {
          padding: loan ? "30px 15px 46px" : "0px",
        },

        "::before": {
          content: '""',
          display: "none",
          position: "absolute",

          "@media screen and (min-width: 992px)": {
            backgroundImage: loan
              ? `url(${
                  hero
                    ? "/imgs/foursparklestopright.svg"
                    : "/imgs/foursparklestopleft.svg"
                })`
              : null,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            display: loan ? "inline-block" : "none",
            left: hero ? "unset" : "-60px",
            height: hero ? 70 : 90,
            right: hero ? "-5px" : "unset",
            top: hero ? "-60px" : "-60px",
            transform: hero ? "rotate(-40deg)" : "unset",
            width: hero ? 70 : 80,
          },
          "@media screen and (min-width: 1200px)": {
            height: 90,
            left: hero ? "unset" : "-60px",
            right: hero ? "-50px" : "unset",
            top: hero ? "-70px" : "-60px",
            transform: "unset",
            width: 80,
          },
        },
      }}
    >
      <iframe
        ref={iframeRef}
        src={config.src}
        sx={{
          border: "none",
          display: "block",
          overflow: "hidden",
          width: "100%",
        }}
        title="Brocc låneformulär"
      />
    </div>
  )
}

BroccForm.defaultProps = {
  hero: false,
  loan: false,
}

BroccForm.propTypes = {
  bgc: PropTypes.string.isRequired,
  hero: PropTypes.bool,
  loan: PropTypes.bool,
  widget: PropTypes.string.isRequired,
  messageHandler: PropTypes.func,
}
