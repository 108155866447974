import locations from "@constants/locations"

const defaultStyles = {
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  content: '""',
  position: "absolute",
}

export default {
  [locations.FOUR_TOP_LEFT]: {
    ...defaultStyles,
    backgroundImage: `url(/imgs/foursparklestopleft.svg)`,
    height: 50,
    left: "-35px",
    top: "-30px",
    width: 50,

    "@media (min-width: 768px) and (max-width: 1200px)": {
      backgroundImage: `url(/imgs/threesparklestopleft.svg)`,
      height: 70,
      left: "-15px",
      top: "-70px",
      transform: "rotate(20deg)",
      width: 90,
    },
    "@media screen and (min-width: 1200px)": {
      height: 90,
      left: "-55px",
      top: "-50px",
      width: 90,
    },
  },
  [locations.FOUR_TOP_RIGHT]: {
    ...defaultStyles,
    backgroundImage: `url(/imgs/foursparklestopright.svg)`,
    height: 50,
    right: "-30px",
    top: "-30px",
    width: 50,

    "@media (min-width: 768px) and (max-width: 1200px)": {
      backgroundImage: `url(/imgs/threesparklestopright.svg)`,
      height: 70,
      right: "-15px",
      top: "-65px",
      transform: "rotate(-10deg)",
      width: 90,
    },
    "@media screen and (min-width: 1200px)": {
      height: 90,
      right: "-55px",
      top: "-50px",
      width: 90,
    },
  },
  [locations.FOUR_BOTTOM_RIGHT]: {
    ...defaultStyles,
    backgroundImage: `url(/imgs/foursparklesbottomright.svg)`,
    bottom: "-25px",
    height: 50,
    right: "-30px",
    width: 50,

    "@media screen and (min-width: 768px)": {
      bottom: "-40px",
      height: 70,
      right: "-15px",
      width: 40,
    },
    "@media screen and (min-width: 1200px)": {
      bottom: "-50px",
      height: 90,
      right: "-55px",
      width: 90,
    },
  },
  [locations.THREE_TOP_LEFT]: {
    ...defaultStyles,
    backgroundImage: `url(/imgs/threesparklestopleft.svg)`,
    top: "-35px",
    height: 50,
    left: "-30px",
    width: 50,

    "@media (min-width: 768px) and (max-width: 1200px)": {
      height: 70,
      left: "-15px",
      top: "-70px",
      transform: "rotate(20deg)",
      width: 90,
    },
    "@media screen and (min-width: 1200px)": {
      top: "-60px",
      height: 90,
      left: "-55px",
      width: 90,
    },
  },
  [locations.THREE_TOP_RIGHT]: {
    ...defaultStyles,
    backgroundImage: `url(/imgs/threesparklestopright.svg)`,
    top: "-35px",
    height: 50,
    right: "-20px",
    width: 50,
    "@media (min-width: 768px) and (max-width: 1200px)": {
      height: 70,
      right: "-15px",
      transform: "rotate(-10deg)",
      top: "-60px",
      width: 90,
    },
    "@media screen and (min-width: 1200px)": {
      top: "-60px",
      height: 90,
      right: "-40px",
      width: 90,
    },
  },
}
