/** @jsx jsx */
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

import Variant from "@components/Variant"

import Link from "./Link"

export const fragment = graphql`
  fragment ContentfulSimpleContactBlock on ContentfulSimpleContactBlock {
    id
    mail
    mailLinkTitle
    mailTitle
    messenger
    messengerLinkTitle
    messengerTitle
    phone
    phoneLinkTitle
    phoneTitle
  }
`

export default function SimpleContact({
  mail,
  mailLinkTitle,
  mailTitle,
  messenger,
  messengerLinkTitle,
  messengerTitle,
  phone,
  phoneLinkTitle,
  phoneTitle,
}) {
  return (
    <Variant
      as="section"
      d="flex"
      sx={{
        flexDirection: ["column", null, "row"],
        justifyContent: "space-between",
      }}
      variants={["container", "containerSpacing"]}
    >
      <Link linkTitle={mailLinkTitle} slug={mail} title={mailTitle} />
      {/* <Link
        blank
        linkTitle={messengerLinkTitle}
        slug={messenger}
        title={messengerTitle}
      /> */}
      <Link linkTitle={phoneLinkTitle} slug={phone} title={phoneTitle} />
    </Variant>
  )
}

SimpleContact.typeName = "ContentfulSimpleContactBlock"

SimpleContact.propTypes = {
  mail: PropTypes.string.isRequired,
  mailLinkTitle: PropTypes.string.isRequired,
  mailTitle: PropTypes.string.isRequired,
  messenger: PropTypes.string.isRequired,
  messengerLinkTitle: PropTypes.string.isRequired,
  messengerTitle: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  phoneLinkTitle: PropTypes.string.isRequired,
  phoneTitle: PropTypes.string.isRequired,
}
