/** @jsx jsx */
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { Flex, jsx } from "theme-ui"

export default function CtaLinks({ links }) {
  if (Array.isArray(links) && links.length) {
    return (
      <Flex
        sx={{
          justifyContent: ["center", "flex-start"],
          flexDirection: ["column", "row"],
        }}
      >
        {links.map(({ href, id, slug, title }) => {
          if (slug) {
            return (
              <Link
                key={id}
                sx={{
                  variant: "button.secondary",

                  "& + &": {
                    ml: [0, 6], // Flex gap is unfortunately not supported by Safari
                    mt: [6, 0],
                  },
                  ":first-of-type": {
                    variant: "button.primary",
                  },
                }}
                to={slug}
              >
                {title}
              </Link>
            )
          }

          if (href) {
            return (
              <a
                href={href}
                key={href}
                sx={{
                  variant: "button.secondary",

                  "& + &": {
                    ml: [0, 6], // Flex gap is unfortunately not supported by Safari
                    mt: [6, 0],
                  },
                  ":first-of-type": {
                    variant: "button.primary",
                  },
                }}
              >
                {title}
              </a>
            )
          }

          return null
        })}
      </Flex>
    )
  }

  return null
}

CtaLinks.defaultProps = {
  links: null,
}

CtaLinks.propTypes = {
  links: PropTypes.array,
}
