/** @jsx jsx */
import { graphql } from "gatsby"
import Lottie from "lottie-react-web"
import PropTypes from "prop-types"
import { jsx } from "theme-ui"

export const fragment = graphql`
  fragment Animation on ContentfulAnimation {
    json
  }
`

export default function Animation({ json }) {
  return <Lottie options={{ animationData: json }} />
}

Animation.propTypes = {
  json: PropTypes.object.isRequired,
}
