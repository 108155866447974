/** @jsx jsx */
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { Flex, jsx } from "theme-ui"

export default function Links({ links }) {
  if (Array.isArray(links) && links.length) {
    return (
      <Flex
        sx={{
          justifyContent: ["center", "flex-start"],
          flexDirection: ["column", "row"],
        }}
      >
        {links.map(({ slug, title, href, isInternal }) => {
          if (isInternal && slug) {
            return (
              <Link
                key={slug}
                sx={{
                  color: "primary",
                  fontSize: [4],
                  fontWeight: "bold",

                  "& + &": {
                    ml: [0, 13],
                    mt: [6, 0],
                  },
                }}
                to={slug}
              >
                {title}
              </Link>
            )
          } else if (href) {
            return (
              <a
                href={href}
                key={href}
                sx={{
                  color: "primary",
                  fontSize: [4],
                  fontWeight: "bold",

                  "& + &": {
                    ml: [0, 13],
                    mt: [6, 0],
                  },
                }}
              >
                {title}
              </a>
            )
          } else {
            return null
          }
        })}
      </Flex>
    )
  }

  return null
}

Links.defaultProps = {
  links: null,
}

Links.propTypes = {
  links: PropTypes.array,
}
