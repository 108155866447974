/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx, Styled } from "theme-ui"

export default function Title({ title }) {
  const titleWOparagraph = title.replace(/<\/?[^>]+(>|$)/g, "")
  if (title) {
    return (
      <Styled.h1
        // dangerouslySetInnerHTML={{ __html: title }}
        sx={{
          textAlign: ["center", "left"],
          p: { variant: "reset" },
          span: { lineHeight: "1.3", whiteSpace: "nowrap" },
        }}
      >
        {titleWOparagraph}
      </Styled.h1>
    )
  }

  return null
}

Title.defaultProps = {
  title: undefined,
}

Title.propTypes = {
  title: PropTypes.string,
}
