/** @jsx jsx */
import PropTypes from "prop-types"
import { jsx, Styled } from "theme-ui"

export default function Text({ text }) {
  if (text) {
    return (
      <Styled.p
        dangerouslySetInnerHTML={{ __html: text }}
        sx={{
          fontSize: [2, 4],
          textAlign: ["center", "left"],
          "@media screen and (min-width: 1084px)": {
            maxWidth: "570px",
          },
        }}
      />
    )
  }

  return null
}

Text.defaultProps = {
  text: undefined,
}

Text.propTypes = {
  text: PropTypes.string,
}
