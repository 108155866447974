/** @jsx jsx */
import PropTypes from "prop-types"
import { Grid, jsx, Styled } from "theme-ui"

function SimpleList({ items }) {
  return (
    <Styled.ul sx={{ variant: "list" }}>
      {items
        ? items.map(({ text, id }) => (
            <Grid
              as="li"
              columns="20px  1fr"
              key={id}
              sx={{
                gap: 4,
                fontSize: 3,
                fontWeight: "bold",
                "& + &": {
                  mt: 6,
                },
              }}
            >
              <img
                alt="bullet"
                aria-hidden="true"
                src="/imgs/brocc-yellow.svg"
              />
              <span sx={{ fontSize: [2, 4], mt: "2px" }}>{text}</span>
            </Grid>
          ))
        : null}
    </Styled.ul>
  )
}

SimpleList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
}

export default SimpleList
