/** @jsx jsx */
import locations from "@constants/locations"
import { graphql } from "gatsby"
import Lottie from "lottie-react-web"
import PropTypes from "prop-types"
import { useMemo } from "react"
import { jsx } from "theme-ui"

import Image from "@components/Image"

import styles from "./styles"

export const fragment = graphql`
  fragment ImageWithEnhancements on ContentfulImage {
    backgroundcolor
    border
    image {
      fluid(maxWidth: 450, quality: 80) {
        ...GatsbyContentfulFluid
      }
      ...ImageFields
    }
    sparkles
  }
`

function ImageWrapper({ children, relative, sparkles }) {
  return (
    <div
      sx={{
        bottom: relative ? null : ["20px", "30px"],
        height: "inherit",
        left: relative ? null : ["20px", "30px"],
        margin: relative ? ["0 auto", "unset"] : "unset",
        maxWidth: relative ? ["270px", "350px", "unset"] : "unset",
        position: relative ? "relative" : "absolute",
        right: relative ? null : ["20px", "30px"],
        top: relative ? null : ["20px", "30px"],
        width: relative ? "100%" : "unset",
        zIndex: 15,

        "::after": sparkles,

        "::before": {
          backgroundImage: `url(/imgs/skew-frame.png)`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          bottom: "0px",
          content: '""',
          left: "0px",
          position: "absolute",
          right: "0px",
          top: "0px",
          zIndex: 20,
        },
      }}
    >
      {children}
    </div>
  )
}

ImageWrapper.defaultProps = {
  relative: false,
  sparkles: {},
}

ImageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  relative: PropTypes.bool,
  sparkles: PropTypes.object,
}

export default function ImageWithEnhancements({ media, nomqs }) {
  let sparkles = useMemo(() => {
    switch (media.sparkles) {
      case locations.FOUR_BOTTOM_RIGHT:
        return styles[locations.FOUR_BOTTOM_RIGHT]
      case locations.FOUR_TOP_LEFT:
        return styles[locations.FOUR_TOP_LEFT]
      case locations.FOUR_TOP_RIGHT:
        return styles[locations.FOUR_TOP_RIGHT]
      case locations.THREE_TOP_LEFT:
        return styles[locations.THREE_TOP_LEFT]
      case locations.THREE_TOP_RIGHT:
        return styles[locations.THREE_TOP_RIGHT]
      default:
        return {}
    }
  }, [media.sparkles])

  if (nomqs) {
    sparkles = {
      backgroundImage: sparkles["backgroundImage"],
      backgroundPosition: sparkles["backgroundPosition"],
      backgroundRepeat: sparkles["backgroundRepeat"],
      backgroundSize: sparkles["backgroundSize"],
      content: sparkles["content"],
      position: sparkles["position"],
      ...sparkles["@media screen and (min-width: 1200px)"],
    }
  }

  if (media.border) {
    return (
      <div
        sx={{
          margin: ["0 auto", "unset"],
          maxWidth: [290, "unset"],
          position: "relative",
          zIndex: 10,

          "::after": sparkles,

          path: {
            fill: "white",
          },

          "> div:first-of-type": {
            height: ["270px", "unset"],
            width: ["auto", null, "unset"],
          },
        }}
      >
        <Lottie
          options={{ animationData: require("@src/lib/border-animation.json") }}
        />
        <ImageWrapper>
          <Image
            aspectRatio={media.image.fluid ? 1.06 : 0.94}
            {...media.image}
          />
        </ImageWrapper>
      </div>
    )
  }

  return (
    <ImageWrapper relative sparkles={sparkles}>
      <Image aspectRatio={media.image.fluid ? 1.06 : 0.94} {...media.image} />
    </ImageWrapper>
  )
}

ImageWithEnhancements.defaultProps = {
  nomqs: false,
}

ImageWithEnhancements.propTypes = {
  media: PropTypes.object.isRequired,
  nomqs: PropTypes.bool,
}
