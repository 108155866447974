/** @jsx jsx */
import PropTypes from "prop-types"
import { Flex, jsx, Styled } from "theme-ui"

export default function Link({ blank, linkTitle, slug, title }) {
  return (
    <Flex
      sx={{
        alignItems: "center",
        flexDirection: "column",

        "& + &": {
          mt: [8, null, 0],
        },
      }}
    >
      <Styled.h3 sx={{ mb: 4 }}>{title}</Styled.h3>
      <a
        href={slug}
        rel="nofollow, noreferrer"
        sx={{
          color: "text",
          fontSize: 0,
          fontWeight: "bold",

          ":hover": {
            color: [null, null, "primary"],
          },
        }}
        target={blank ? "_blank" : "_self"}
      >
        {linkTitle}
      </a>
    </Flex>
  )
}

Link.defaultProps = {
  blank: false,
}

Link.propTypes = {
  blank: PropTypes.bool,
  linkTitle: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}
