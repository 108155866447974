/** @jsx jsx */
import PropTypes from "prop-types"
import { Flex, Grid, jsx } from "theme-ui"

import Media from "@components/Media"
import RichText from "@components/RichText"
import SimpleList from "@components/SimpleList"

import CtaLinks from "./CtaLinks"
import Links from "./Links"
import Text from "./Text"
import Title from "./Title"

export default function Hero({
  children,
  ctaLinks,
  json,
  links,
  list,
  media,
  text,
  title,
  roundedImage,
}) {
  let columns = ["1fr", "1fr 1fr"]
  if (media) {
    if (media.formWidget) {
      columns = ["1fr", "auto minmax(670px, 1fr)"]
    } else {
      columns = ["1fr", "1fr 1fr", "9fr 10fr"]
    }
  }
  return (
    <Grid
      as="div"
      columns={columns}
      gap={[10]}
      sx={{
        alignContent: "flex-start",
        width: "100%",
      }}
    >
      <Flex
        sx={{
          alignItems: "center",
          height: "100%",
          justifySelf: ["center", "unset"],
          maxHeight: [media?.formWidget || media?.json ? null : 270, "unset"],
          maxWidth: [
            media?.formWidget ? null : media?.json ? 300 : 450,
            null,
            "unset",
          ],
          px: media?.formWidget ? [0, 6] : 6,
          width: "100%",
        }}
      >
        <Media roundedImage={roundedImage} media={media} />
      </Flex>
      <Grid
        as="div"
        gap={[7, 10]}
        sx={{
          alignSelf: media?.formWidget ? "flex-start" : "center",
          justifySelf: [
            "center",
            media?.formWidget ? null : "flex-start",
            "flex-start",
          ],
          order: [media?.formWidget ? -1 : null, -1],
          maxWidth: [450, null, "unset"],
          pt: media?.formWidget ? [null, 12] : 0,
          px: 6,
        }}
      >
        <Title title={title} />
        <Text text={text} />
        {json ? <RichText json={json} pv="preamble" /> : null}
        {list ? <SimpleList items={list} /> : null}
        <CtaLinks links={ctaLinks} />
        <Links links={links} />
        {children}
      </Grid>
    </Grid>
  )
}

Hero.defaultProps = {
  children: null,
  ctaLinks: null,
  json: null,
  links: null,
  list: null,
  media: null,
  text: undefined,
  title: undefined,
}

Hero.propTypes = {
  children: PropTypes.node,
  ctaLinks: PropTypes.array,
  json: PropTypes.object,
  links: PropTypes.array,
  list: PropTypes.array,
  media: PropTypes.object,
  text: PropTypes.string,
  title: PropTypes.string,
}
